.wrapper-main {
	max-width: 1200px;
	margin: 0 auto;
}
.wrapper-main h1 {
	padding: 20px 0;
	text-align: center;
	text-transform: uppercase;
}
.project {
	display: flex;
}
.shop {
	flex: 75%;
}
.box-cont {
	display: flex;
	width: 100%;
	height: 200px;
	overflow: hidden;
	margin-bottom: 20px;
	background: #fff;
	transition: all .6s ease;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.box-cont:hover {
	border: none;
	transform: scale(1.01);
}
.box-cont img {
	width: 300px;
	height: 200px;
	object-fit: cover;
}
.content {
	padding: 20px;
	width: 100%;
	position: relative;
}
.content h3 {
	margin-bottom: 30px;
}
.content h4 {
	margin-bottom: 50px;
}
.btn-area {
	position: absolute;
	bottom: 20px;
	right: 20px;
	padding: 10px 25px;
	background-color: #d3ad7f;
	color: white;
	cursor: pointer;
	border-radius: 5px;
}
.btn-area:hover {
	background-color: #ad8d65;
	color: #fff;
	font-weight: 600;
}
.unit input {
	width: 40px;
	padding: 5px;
	text-align: center;
}
.btn-area i {
	margin-right: 5px;
}
.right-bar {
	flex: 25%;
	margin-left: 20px;
	padding: 20px;
	height: 400px;
	border-radius: 5px;
	background: #fff;
	box-cont-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.right-bar hr {
	margin-bottom: 25px;
}
.right-bar p {
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px;
	font-size: 20px;
}
.right-bar a {
	background-color: #d3ad7f;
	color: #fff;
	text-decoration: none;
	display: block;
	text-align: center;
	height: 40px;
	line-height: 40px;
	font-weight: 900;
}
.right-bar i {
	margin-right: 15px;
}
.right-bar a:hover {
	background-color: #ad8d65;
}
@media screen and (max-width: 700px) {
	.content h3 {
		margin-bottom: 15px;
	}
	.content h4 {
		margin-bottom: 20px;
	}
	.btn2 {
		display: none;
	}
	.box-cont {
		height: 150px;
	}
	.box-cont img {
		height: 150px;
		width: 200px;
	}
}
@media screen and (max-width: 900px) {
	.project {
		flex-direction: column;
	}
	.right-bar {
		margin-left: 0;
		margin-bottom: 20px;
	}
}
@media screen and (max-width: 1250px) {
	.wrapper-main {
		max-width: 95%;
	}
}