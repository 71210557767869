#hero {
            height:100vh;
            width:100%;
            background: #d3ad7f;
            display:flex;
            align-items:center;
            justify-content: center;
}



#hero::after {
      width:100%;
      height:100%;
      content: '';
      position:absolute;
      z-index:10;
      top:0;
      left:0;
      background:rgba(0,0,0,0.5)
}


#hero video {
      width:100%;
      height:100%;
      position:absolute;
      top:0;
      left:0;
      z-index:5;
      object-fit:cover;
      font-family:'object-fit: cover';
}


#hero .texture {
      width:100%;
      height:100%;
      position:absolute;
      top:0;
      left:0;
      z-index:15;
      /* background:url('../texture.png'); */
}


#hero .caption {

      position:relative;
      z-index:20;
      text-align:center;
      color:#ffffff;
}



#hero .caption h1 {
      color:#ffffff;
      text-transform: uppercase;
      font-size:1.8em;
      margin-bottom:0.5rem;
}

#hero .caption p{
      font-size: 20px;
      
}

#hero .caption a{
      font-size: 18px !important;
      color: #d3ad7f;
}

#hero .caption h2 {
      font-weight:400;
      font-size:1.5rem;
      margin:0;
      font-family: 'PT Sans', sans-serif;
}




@media screen and (min-width:768px)
{
      #hero .caption h1 {
            font-size:2.5rem;
      }

      #hero .caption h2 {
            font-size:1.75rem;
      }
}




@media screen and (min-width:992px)
{
      #hero .caption h1 {
            font-size:3rem;
      }

      #hero .caption h2 {
            font-size:2rem;
      }
}



@media screen and (min-width:1200px)
{
      #hero .caption h1 {
            font-size:4rem;
      }

      #hero .caption h2 {
            font-size:2.5rem;
      }
}