@media (max-width: 768px) {
    #about{
    .rsis-container {
      // do something
      width: 50% !important;
    }
  }
  #about {
    .rsis-image {
      background-size: cover !important;
      max-width: 100% !important;
      height: auto !important;
    }
  }
}

