@font-face {
    font-family: "Avenir Next";
    src: url("fonts/AvenirNextLTPro-Regular.otf")
  }
:root{
    --main-color:#d3ad7f;
    --black:#13131a;
    --bg:#010103;
    --border:.1rem solid rgba(255,255,255,.3);
    --text: #fff;
    --bg-white: #fff;
    --gray: #ccc;
    --text-black: #0a0a0e;
    --text-reverse: #fff;
    --text-white: #000;
}

[data-theme='light']{
    --main-color:#13131a;
    --black:#dadae4;
    --bg:#ececec;
    --border:.1rem solid #000;
    --text: #0a0a0e;
    --bg-white: #000;
    --text-black: #000;
    --text-reverse: #000;
    --text-white: #fff;
} 


.text-main{
    color: var(--main-color) !important;
}

*{
    font-family: 'Avenir Next', sans-serif;
    margin:0; padding:0;
    box-sizing: border-box;
    outline: none; border:none;
    text-decoration: none;
    transition: .2s linear;
}

html{
    font-size: 62.5%;
    overflow-x: hidden;
    scroll-padding-top: 9rem;
    scroll-behavior: smooth;
}

html::-webkit-scrollbar{
    width: .8rem;
}

html::-webkit-scrollbar-track{
    background: transparent;
}

html::-webkit-scrollbar-thumb{
    background: var(--bg-white);
    border-radius: 5rem;
}

body{
    background: var(--bg) !important;
}

#about,
#menu,
#products,
#review,
#contact,
#blogs
{
    padding:2rem 7%;
}

.list-custom-menu{
    font-size: 15px !important;
   
}

.list-custom-menu .list-group-item{
    background-color: var(--bg) !important;
    color: var(--text);
    
}

.heading{
    text-align: center;
    color: var(--text);
    text-transform: uppercase;
    padding-bottom: 3.5rem;
    font-size: 2rem;
}

.heading span{
    color:var(--main-color);
    text-transform: uppercase;
}

.b-none{
    background-color: transparent !important;
}

.btn{
    /* margin-top: 1rem; */
    display: inline-block;
    padding:.9rem 3rem;
    font-size: 1.7rem;
    color: var(--text);
    background: var(--main-color);
    cursor: pointer;
}

/* .btn:hover{
    letter-spacing: .2rem;
} */

.text-white-main{
    color: var(--text) !important;
}

.text-black-main{
    color: var(--text-black) !important;
}

.header{
    background: var(--bg);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:1.5rem 7%;
    border-bottom: var(--border);
    position: fixed;
    top:0; left: 0; right: 0;
    z-index: 1000;
    text-transform: capitalize;
}

.header .logo img{
    height: 6rem;
}

.header .navbar a{
    margin:0 1rem;
    font-size: 1.6rem;
    color: var(--text);
}

.header .navbar a:hover{
    color:var(--main-color);
    border-bottom: .1rem solid var(--main-color);
    padding-bottom: .5rem;
}

.header .icons div{
    color: var(--text);
    cursor: pointer;
    font-size: 2.5rem;
    margin-left: 2rem;
}

.header .icons div:hover{
    color:var(--main-color);
}

#menu-btn{
    display: none;
}

.header .search-form{
    position: absolute;
    top:115%; right: 7%;
    background: var(--text);
    width: 50rem;
    height: 5rem;
    display: flex;
    align-items: center;
    transform: scaleY(0);
    transform-origin: top;
}

.header .search-form.active{
    transform: scaleY(1);
}

.header .search-form input{
    height: 100%;
    width: 100%;
    font-size: 1.6rem;
    color:var(--black);
    padding:1rem;
    text-transform: none;
}

.header .search-form label{
    cursor: pointer;
    font-size: 2.2rem;
    margin-right: 1.5rem;
    color:var(--black);
}

.header .search-form label:hover{
    color:var(--main-color);
}

.header .cart-items-container{
    position: absolute;
    top:100%; right: -100%;
    height: calc(100vh - 9.5rem);
    width: 35rem;
    background: var(--text);
    padding:0 1.5rem;
}

.header .cart-items-container.active{
    right: 0;
}

.header .cart-items-container .cart-item{
    position: relative;
    margin:2rem 0;
    display: flex;
    align-items: center;
    gap:1.5rem;
}

.header .cart-items-container .cart-item .fa-times{
    position: absolute;
    top:1rem; right: 1rem;
    font-size: 2rem;
    cursor: pointer;
    color: var(--black);
}

.header .cart-items-container .cart-item .fa-times:hover{
    color:var(--main-color);
}

.header .cart-items-container .cart-item img{
    height: 7rem;
}

.header .cart-items-container .cart-item .content h3{
    font-size: 2rem;
    color:var(--black);
    padding-bottom: .5rem;
}

.header .cart-items-container .cart-item .content .price{
    font-size: 1.5rem;
    color:var(--main-color);
}

.header .cart-items-container .btn{
    width: 100%;
    text-align: center;
}

.home{
    min-height: 100vh;
    display: flex;
    align-items: center;
    background:url('../../public/images/home-img.jpeg') no-repeat;
    background-size: cover;
    background-position: center;
}

.home .content{
    max-width: 100%;
}

.home .content h3{
    font-size: 4rem;
    text-transform: uppercase;
    color:var(--text);
    margin-top: 18rem;
}

.home .content p{
    font-size: 2rem;
    font-weight: lighter;
    line-height: 1.8;
    padding:1rem 0;
    color:var(--text);
}

.about .row{
    display: flex;
    align-items: center;
    background:var(--black);
    flex-wrap: wrap;
}

.about .row .image{
    flex:1 1 45rem;
}

.about .row .image img{
    width: 100%;
}
.about .row .content{
    flex:1 1 45rem;
    padding:2rem;
}

.about .row .content h3{
    font-size: 3rem;
    color:var(--text);
}

.about .row .content p{
    font-size: 1.6rem;
    color:#ccc;
    padding:1rem 0;
    line-height: 1.8;
}

.about-img{
    padding: 0;
    margin: 0;
}

.menu .box-container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    gap:1.5rem;
}

.menu .box-container .box{
    padding:5rem;
    text-align: center;
    border:var(--border);    
    border-radius: 10px !important;
}

.menu .box-container .box img{
    height: 10rem;
}

.menu .box-container .box h3{
    color:var(--text);
    font-size: 2rem;
    padding:1rem 0;
}

.menu .box-container .box .price{
    color:var(--text);
    font-size: 2.5rem;
    padding:.5rem 0;
}

.menu .box-container .box .price span{
    font-size: 1.5rem;
    text-decoration: line-through;
    font-weight: lighter;
}

.menu .box-container .box:hover{
    background: var(--bg-white);
    color: var(--text-white) !important;
}


.menu .box-container .box h3:hover, .menu .box-container .box .price:hover{
    color: var(--text-white) !important;
}

.menu .box-container .box > *:hover{
    color: var(--text-white) !important;
}

.products .box-container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    gap:1.5rem;
}

.products .box-container .box{
    text-align: center;
    border:var(--border);
    padding: 2rem;
    border-radius: 20px !important;
}

.products .box-container .box .icons a{
    height: 5rem;
    width: 5rem;
    line-height: 5rem;
    font-size: 2rem;
    border:var(--border);
    color: var(--text);
    margin:.3rem;
}

.products .box-container .box .icons a:hover{
    background:var(--main-color);
}

.products .box-container .box .image{
    padding: 2.5rem 0;
}

.products .box-container .box .image img{
    height: 25rem;
}

.products .box-container .box .content h3{
    color: var(--text);
    font-size: 2.5rem;
}

.products .box-container .box .content .stars{
    padding: 1.5rem;
}

.products .box-container .box .content .stars i{
    font-size: 1.7rem;
    color: var(--main-color);
}

.products .box-container .box .content .price{
    color: var(--text);
    font-size: 2.5rem;
}

.products .box-container .box .content .price span{
    text-decoration: line-through;
    font-weight: lighter;
    font-size: 1.5rem;
}

.review .box-container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    gap:1.5rem;
}

.review .box-container .box{
    border:var(--border);
    text-align: center;
    padding:3rem 2rem;
}

.review .box-container .box p{
    font-size: 1.5rem;
    line-height: 1.8;
    color:#ccc;
    padding:2rem 0;
}

.review .box-container .box .user{
    height: 7rem;
    width: 7rem;
    border-radius: 50%;
    object-fit: cover;
}

.review .box-container .box h3{
    padding:1rem 0;
    font-size: 2rem;
    color: var(--text);
}

.review .box-container .box .stars i{
    font-size: 1.5rem;
    color:var(--main-color);
}

.contact .row{
    display: flex;
    background:var(--black);
    flex-wrap: wrap;
    gap:1rem;
}

.contact .row .map{
    flex:1 1 45rem;
    width: 100%;
    object-fit: cover;
}

.contact .row form{
    flex:1 1 45rem;
    padding:5rem 2rem;
    text-align: center;
}

.contact .row form h3{
    text-transform: uppercase;
    font-size: 3.5rem;
    color: var(--text);
}

.contact .row form .inputBox{
    display: flex;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    background:var(--bg);
    border:var(--border);
}

.contact .row form .inputBox span{
    color: var(--text);
    font-size: 2rem;
    padding-left: 2rem;
}

.contact .row form .inputBox input{
    width: 100%;
    padding:2rem;
    font-size: 1.7rem;
    color: var(--text);
    text-transform: none;
    background:none;
}

.contact .row form .inputBox textarea{
    width: 100%;
    padding:2rem;
    font-size: 1.7rem;
    color: var(--text);
    text-transform: none;
    background:none;
}

.blogs .box-container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    gap:1.5rem;
}

.blogs .box-container .box{
    border:var(--border);    
}

.blogs .box-container .box .image{
    height: 25rem;
    overflow:hidden;
    width: 100%;
}

.blogs .box-container .box .image img{
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.blogs .box-container .box:hover .image img{
    transform: scale(1.2);
}

.blogs .box-container .box .content{
    padding:2rem;
}

.blogs .box-container .box .content .title{
    font-size: 2.5rem;
    line-height: 1.5;
    color: var(--text);
}

.blogs .box-container .box .content .title:hover{
    color:var(--main-color);
}

.blogs .box-container .box .content span{
    color:var(--main-color);
    display: block;
    padding-top: 1rem;
    font-size: 2rem;
}

.blogs .box-container .box .content p{
    font-size: 1.6rem;
    line-height: 1.8;
    color:#ccc;
    padding:1rem 0;
}

.footer{
    background:var(--black);
    text-align: center;
}

.footer .share{
    padding:1rem 0;
}

.footer .share a{
    height: 5rem;
    width: 5rem;
    line-height: 5rem;
    font-size: 2rem;
    color: var(--text);
    border:var(--border);
    margin:.3rem;
    border-radius: 50%;
}

.footer .share a:hover{
    background-color: var(--main-color);
}

.footer .links{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding:2rem 0;
    gap:1rem;
}

.footer .links a{
    padding:.7rem 2rem;
    color: var(--text);
    border:var(--border);
    font-size: 2rem;
}

.footer .links a:hover{
    background:var(--main-color);
}

.footer .credit{
    font-size: 2rem;
    color: var(--text);
    font-weight: lighter;
    padding:1.5rem;
}

.footer .credit span{
    color:var(--main-color);
}


/* media queries  */
@media (max-width:991px){

    html{
        font-size: 55%;
    }

    .header{
        padding:1.5rem 2rem;
    }

    #about,
    #menu,
    #products,
    #review,
    #contact,
    #blogs,
    #home
    {
        padding:2rem;
    }

}

@media (max-width:768px){

    #menu-btn{
        display: inline-block;
    }

    .header .navbar{
        position: absolute;
        top:100%; right: -100%;
        background: var(--text);
        width: 30rem;
        height: calc(100vh - 9.5rem);
    }

    .header .navbar.active{
        right:0;
    }

    .header .navbar a{
        color:var(--black);
        display: block;
        margin:1.5rem;
        padding:.5rem;
        font-size: 2rem;
    }

    .header .search-form{
        width: 90%;
        right: 2rem;
    }

    .home{
        background-position: left;
        justify-content: center;
        text-align: center;
    }

    .home .content h3{
        font-size: 4.5rem;
        margin-top: 0.45rem;
    }

    .home .content p{
        font-size: 1.5rem;
    }

}
.video-mask{
    border-radius: 20px; 
    overflow: hidden;
    -webkit-transform: translateZ(0);
}

@media (max-width:450px){

    html{
        font-size: 50%;
    }
    .video{
        width: 100%;
        height: 50%;
    }

}

.text-white{
    color: var(--text) !important;
}

.link-white{
    color: var(--text) !important;
}

.link-white:hover{
    color: #777 !important;
    transition: 0.4s ease-in;
}

.contact-item{
    margin-top: 20px;
    font-size:18px;
}

/* margins */
.mt-6{
    margin-top: 9rem !important;
}
.mt-8{
    margin-top: 11rem !important;
}
.mb-8{
    margin-bottom: 11rem !important;
}

.mb-6{
    margin-bottom: 9rem !important;
}
.my-6{
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
}

.my-8{
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
}

.my-10{
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
}

/* card styles */

.rounded-10{
    border-radius: 10px !important;
}

.rounded-20{
    border-radius: 20px !important;
}

.rounded-top-right-10{
    border-top-right-radius: 10px !important;
}

.rounded-top-left-10{
    border-top-left-radius: 10px !important;
}

.rounded-top-right-20{
    border-top-right-radius: 20px !important;
}

.rounded-top-left-20{
    border-top-left-radius: 20px !important;
}

.rounded-bottom-right-10{
    border-top-right-radius: 10px !important;
}

.rounded-bottom-left-10{
    border-top-left-radius: 10px !important;
}

.rounded-bottom-right-20{
    border-top-right-radius: 20px !important;
}

.rounded-bottom-left-20{
    border-top-left-radius: 20px !important;
}

.rounded-top-10{
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
}

.rounded-top-20{
    border-top-right-radius: 20px !important;
    border-top-left-radius: 20px !important;
}

.rounded-bottom-20{
    border-bottom-right-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
}

.rounded-bottom-10{
    border-bottom-right-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
}

.rounded-50{
    border-radius: 50px !important;
}

.card-2{
    background-color: var(--bg) !important;
    border: 1px solid var(--text) !important;
}

.form-control,.btn{
    font-size: 1.3rem !important;
}

.text-12{
    font-size: 12px !important;
}

.text-13{
    font-size: 13px !important;
}

.text-14{
    font-size: 14px !important;
}

.text-15{
    font-size: 15px !important;
}

.text-18{
    font-size: 18px !important;
}

.text-15{
    font-size: 15px  !important;
}

.text-16{
    font-size: 16px !important;
}

.text-17{
    font-size: 17px !important;
}

.profile-pic-wrapper {
  left: 50%;
  margin-left: -37px; 
  top: 3.5rem;
}

.feedback {
  background-color : var(--bg);
  color: var(--text);
  padding: 10px 20px;
  border-radius: 4px;
  border-color: var(--border);
}

#mybutton {
  position: fixed;
  bottom: -4px;
  right: 10px;
}